.installerComponent {
    padding: 20px;
}
.installerComponent h6.installer-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #999999;
    position: relative;
    top: 10px;
}
.installerComponent .table td, .installerComponent .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 0px solid #f5f6f8;
    border-bottom: 2px solid #f5f6f8;
    padding-left: 0;
    display: table-cell;
    width: 25%;
    text-align: left;
}
.installerComponent .table td:last-child{
    text-align: right;
    color: #437FED;
}
.finishSetupbtn{
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
    border-radius: 40px;
    color: #FFFFFF;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.finishSetupbtn a{
    color: #ffffff !important;
}
/* .downloadInstallernewfn {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #ffffff;
    padding-left: 20px;
    width: 105%;
    height: 100%;
    overflow-y: hidden;
} */

/* .downloadInstallernewfn {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: #ffffff;
    padding-left: 20px;
    width: 105%;
    height: 570px;
    overflow-y: hidden;
    min-height: 530px;
} */
.downloadInstallernewfn {
    position: absolute;
    top: 0;
    background-color: #fff;
    right: auto;
    width: 100%;
    padding-left: 20px;
    
    }
.downloadInstallernewfn:after{
    content: "";
}

.connector-popup.marketplacesec .downloadInstallernewfn {
    width: 105%;
}
.cursor-pointer{
    cursor: pointer;
}