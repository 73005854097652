.editConfig {
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    border-radius: 4px;
    background: transparent;
    padding: 2px 8px;
}
.editConfig i{
    font-size: 22px !important;
}
.cursorBorder{
    border-color:#1E54B8 !important;
}
th.action{
    width: 250px;
}
button.save{
    margin-right: 10px;
}
.border-color-primary{
    border-color: #5b73e8!important
}
.border-color-danger{
    border-color: #f46a6a!important
}
.border-color-success{
    border-color: #34c38f!important
}

.configure .nav-pills .nav-link {
    height: auto;
}
.configback {
    font-size: 16px !important;
    text-decoration: none !important;
}
.configback .uil-angle-left:before {
    font-size: 28px;
    float: left;
    margin-right: -18px;
    margin-top: 0px;
}
.showPassword {
    position: relative;
    margin-top: -33px;
    z-index: 99999;
    float: right;
    cursor: pointer;
    margin-right: 10px;
}
.configback.customDownloadnew span {
    margin-right: 15px;
    position: relative;
    top: -3px;
}