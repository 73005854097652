.newPopover{
    position: absolute;
    z-index: 999999;
    right: 0px;
    top: 45px;
}
.popover-inner{
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
}

.popover-body .footerButton{
    bottom: auto;
    position: relative;
    
}
.main-content.mn-summaryDetails{
    overflow: visible;
}