.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mn-summaryDetails .table-responsive{
  overflow-x: visible !important;
}

.parentBoxofLoader{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0;
  background-color: #191c1ee3;
  z-index: 99;
}
.spinnerLanding{
  display: inline-block;
  border-top: 4px solid currentcolor;
  border-right: 4px solid currentcolor;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99999px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-bottom-color: #E2E8F0;
  border-left-color: #E2E8F0;
  animation: 0.65s linear 0s infinite normal none running animation-b7n1on;
  width: 3rem;
  height: 3rem;
  --spinner-size: 3rem;
  color: #3182ce;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
}

@keyframes animation-b7n1on {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}


:where(img, video) {
  max-width:unset !important;
  height: auto;
}

.versionBox input, .versionBox select option[data-default] {
  color: #1E54B8 !important;
  background: transparent !important
}