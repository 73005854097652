.welcome .section1 .customersupport{
    font-size: 13px !important;
}
.welcome .offersectionnew img {
    width: 100px;
    margin-left: 10px;
}
.updateh5 {
    width: 100%;
    padding: 0 10px;
}
.cmnNewsection {
    padding: 25px 0;
}
.updateh5 a:hover{
    text-decoration: underline !important;
}
.welcome .section1{
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 118px;
    color: #ffffff;
}
.welcome .section1 h5{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    color: #FFFFFF;
}
.welcome .section1 p{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #F7F5F7 !important;
}
.welcome .section1 .customersupport{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px !important;
    line-height: 101.5%;
    color: #FFFFFF;
    margin-top: 7px;
}
.welcome-head h3{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #444444;
}
.custom-connector{
    background: #FFFFFF;
    border-radius: 24.0796px;
    height: 150px;
}
.custom-connector .imgContainer {
    width: 86px;
    height: 86px;
    background: #FFFFFF;
    border: 1.22222px solid rgba(26, 53, 194, 0.3);
    box-sizing: border-box;
    border-radius: 100%;
    margin: 0px auto;
    overflow: hidden;
    text-align: center;
    position: relative;
}
.custom-connector .imgContainer img {
    width: 90%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.custom-connector h5 {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 126.7%;
    color: #666666;
    text-align: center;
    margin-top: 5px;
}
.welcome .artifact-connector{
    width: 117px;
    height: 117px;
    background: #F2F4FE;
    border-radius: 100%;
}
.welcome .offersectionnew{
    height: 106px;
    background: #F2F4FE;
    border-radius: 8px;
}
.cursor-pointer{
    cursor: pointer;
}
.welcomecustomerSupport {
    max-width: 80%;
    padding-left: 0;
    padding-right: 0;
}
.welcomecustomerSupport iframe{
    overflow: hidden;
}
.welcome .card.custom-connector:hover h5{
    color: #5b73e8;
}
.welcome .artifact-connector .imgContainer{
    overflow: hidden;
}
.welcome .fade:not(.show){
    opacity: 1 !important;
    background-color: #000000c2;
}
.welcome .modal-dialog {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    top: 80px;
}

.modal-content.welcomePopup .createConnector {
    position: relative;
    border: 3px solid #0056f3;
    background-color: #ffffff;
}
.modal-content.welcomePopup .createConnector img{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.welcomecustomerSupport{
    height: 100%;
}
.welcomecustomerSupport .slide-pane__content{
    overflow-y: hidden;
}

.partnerImg {
    max-width: 120px;
}
.welcome .updateCB{
    padding: 0px !important;
}

.welcome .updateCB .connector-listing{
    width: 100%;
    box-shadow: none;
}
.welcomecustomerSupport .slide-pane__content{
    padding-left: 10px !important;
}