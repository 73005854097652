.mlr-176 {
    margin: 0 110px;
}
.setup-sectionnew:first-child{
    margin-right: 40px;
}
.setup-sectionnew{
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.setup-sectionnew .card-header {
    background: #F2F4FE;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 126.7%;
    color: #444444;
}
.setupProgresspercentage{
    width: 163px;
    height: 163px;
    background: #F2F4FE;
    border-radius: 50%;
}
.versionNew, .timeElapsedNew, .etaNew{

}
.versionNew span, 
.timeElapsedNew span, 
.etaNew span{
    display: block;    
}

span.mainTitle{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 126.7%;
    color: #999999;
    margin-bottom: 5px;
}

.subTitle{
    margin-bottom: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 126.7%;
    color: #444444;
}
.numberPercentage {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 43.188px;
    line-height: 126.7%;
    color: #1E54B8;
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
    display: block;
    text-align: center;
}
.setup-sectionnew .media.border-bottom{
    border-bottom: 1px solid #C6C6C6 !important;
}
.progressSteps{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 126.7%;
    color: #999999;
    display: block;
    margin-top: 15px;
}
.progressStepTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 126.7%;
    color: #666666;
    display: block;
    margin-top: 10px;
    max-width: 285px;
}
.startSetupButton {
    margin-top: 15px !important;
    width: 100%;
    text-align: center;
}
.startSetupButton button{
    width: 100%;
    text-align: center;
    display: block;
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
    border-radius: 50px;
    height: 50px;
}

.mt-80{
    margin-top: 80px;
}
button:disabled{
    cursor:not-allowed;
}
.mzeroauto{
    margin: 0 auto;
}
#etaNewApproach{
    text-transform: uppercase;
}
.setup-sectionnew .media.border-bottom.custom-height-new{
    height: 200px;
}
.setup-sectionnew .media-body.align-self-center.overflow-hidden.mr-4{
    display: contents;
}

.spinLoaderContainer{
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 99999;
    background-color: #ffffffc7;
    max-width: 265px;
}

.custom-control-input:checked~.custom-control-label:before{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.setup-sectionnew{
    overflow: hidden;
}

.timerCounter{
    
}