*{
    margin: 0px;
    padding: 0px;
}
.body{
    background: #fff;
    background-image: url("https://cdn.dckapintegrator.com/images/v1/general/login-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
}
.header{

}
.logo{
    width: 80px;
}
.parentBoxofLoader {
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    position: fixed; /* Fixed positioning for full body coverage */
    top: 0;
    left: 0;
    background-color: rgb(25 28 30);
    z-index: 99;
    display: flex; /* Flexbox centering */
    justify-content: center;
    align-items: center;
  }