.confirmPopup {
    background-color: #000000cf;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
.confirmPopup .popupContainer {
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    margin-top: 55px;
}
.confirmPopup .popupContainer .card-ftr.text-muted{
    padding: 10px 20px;
}
.confirmPopup .popupContainer button.awsnext{
    font-size: 14px;
}
.confirmPopup .popupContainer .card-header{
    font-size: 18px;
}
.confirmPopup .popupContainer .card-body{
    padding: 60px 0;
}