.awssetup {
    position: absolute;
    top: 62px;
    width: 100%;
    background-color: #ffffff;
    min-height: 600px;
    left: 0;
    padding: 0 35px;
    z-index: 99999;
}
.egeAwstboxes{
    width: 100%;
    height: 203px;
    background: #F2F4FE;
    border-radius: 4.88889px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}
.awsnext {
    width: 158.33px;
    height: 49.89px;
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
    border-radius: 40px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 19.5556px;
    line-height: 120%;
    /* display: flex; */
    align-items: center;
    color: #FFFFFF;
    text-align: center;
    float: right;
}
.awsnext a{
    color: #FFFFFF !important;
}
.errorStateSelect {
    font-size: 14.6667px;
    line-height: 17px;
    color: #DC332E !important;
    border-left: 4px solid #DC332E !important;
}
.errorStateSelect::placeholder {
    color: #DC332E !important;
    opacity: 1;
}
.errorInfoicon {
    position: absolute;
    top: 16px;
    right: 20px;
    width: 16px;
    height: 16px;
    background-color: #1156f1;
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
}
input.aws_version-field{
    padding-right: 30px;
}
.errorInfoMessage {
    position: absolute;
    background-color: #000000;
    color: #ffffff;
    padding: 5px 6px;
    z-index: 9;
    margin-top: -6px;
    width: 301px;
    border-radius: 4px;
    font-size: 12px;
    right: 13px;
}
.errorInfoMessage:before {
    content: "";
    width: 12px;
    height: 12px;
    background-color: #000000;
    position: absolute;
    top: -6px;
    right: 10px;
    transform: rotate(45deg);
}
.errorInfoicon:hover + .errorInfoMessage{
    display: inline-block !important;
}
#adminUsernameerrorInfoicon,
#adminFirstnameerrorInfoicon,
#adminLastNameerrorInfoicon,
#databaseusernameerrorInfoicon{
    display: none;
}
.validationFormatlist {
    padding: 0 20px;
    line-height: 20px;
}
.validationFormatlist li{

}
.validClass .errorStateSelect {
    font-size: 14.6667px;
    line-height: 17px;
    color: #DC332E !important;
    border-left: 4px solid #DC332E !important;
}
.validClass .errorStateSelect::placeholder {
    color: #DC332E !important;
    opacity: 1;
}
.validClass .errorStateSelect,
.validClass .errorStateSelect div{
    color: #DC332E;
}