.Button{
    background: #3182ce !important;
    color: #FFFFFF !important;
    float: right;
}
.parentBoxofLoader{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    background-color: #191c1ee3;
    z-index: 99;
  }
  .floatingLabelFormControl {
    position: relative;
    margin-bottom: 1.5rem;
}

.floatingLabel {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
    padding: 0 0.25rem;
    transition: all 0.2s ease-out;
    pointer-events: none;
    color: #999;
    font-size: 0.85rem !important;
}

.hasValue + .floatingLabel, /* When the input has a value */
input:focus + .floatingLabel { /* When the input is focused */
    top: -0.75rem;
    font-size: 0.85rem;
    color: #3182ce;
    background: white !important;
}

