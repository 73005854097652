.padding-top-20{
    padding-top: 20px;
}
.marketplace .page-title-box h4 {
    font-weight: 600;
    font-size: 18px !important;
    width: 100%;
    border-bottom: 1px solid #C6C6C6;
    padding-bottom: 10px;
}
.marketplace .page-content::before {
    content: "";
    background: #F2F4FE;
    width: 100%;
    height: 270px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 72px;
}
.marketplace .nav-tabs-custom {
    width: 350px;
    border-bottom: 2px solid transparent;
    position: relative;
    top: -55px;
    left: 150px;
}
.marketplace .nav-tabs .nav-item.show .nav-link,
.marketplace .nav-tabs .nav-link.active {
    color: #495057;
    background-color: transparent;
    border-color: #ced4da #ced4da #fff;
}
.marketplace .tab-content{
    width: 100%;
    padding: 20px 12px;
    top: -65px;
    position: relative;
}
.marketplace .trending-offers {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 126.7%;
    color: #000000;
    margin: 10px 0 20px 0px;
}
.marketplace .tab-content .card.mndesc{
    background-color: transparent;
}
ul.trendingList{
    float: left;
    width: 100%;
}
ul.trendingList li.trendingListItem {
    width: 137.22px;
    height: 137px;
    background: #FFFFFF;
    border-radius: 24.0796px;
    list-style: none;
    float: left;
    margin: 0 10px;
}
ul.trendingList li.trendingListItem:first-child{
    margin-left: 0px;
}
ul.trendingList li.trendingListItem:last-child{
    margin-right: 0px;
}
ul.trendingList li.trendingListItem .imgCircle {
    width: 86px;
    height: 86px;
    background: #FFFFFF;
    border: 1.22222px solid rgba(26, 53, 194, 0.3);
    box-sizing: border-box;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    top: 15px;
    text-align: center;
}
ul.trendingList li.trendingListItem .imgCircle img {
    margin: 14px 0 0px 6px;
}
h4.offerTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 126.7%;
    color: #000000;
    position: relative;
    margin: 20px 0 0 0;
    text-align: center;
}
.new-tab-pane-ege{
    position: absolute;
    background-color: #ffffff;
    width: 110%;
    left: -10px;
    padding: 20px;
    padding-top: 10px;
}
.marketPlacetypesContent{
    width: 100%;
    padding: 20px;
}
.marketPlacetypesContent .button-items button {
    border-radius: 24.0796px;
    padding: 10px 30px;
    margin-bottom: 20px;
}
.marketPlacetypesContent .button-items button.active{
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
}
.marketPlacetypesContent .button-items button:hover{
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
}
.marketplaceserplist{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 24.0796px;
}
.marketplaceserplist-title{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 126.7%;
    color:#1E54B8 !important;
}
.viewMore{
    text-align: right;
    width: 100%;
    float: right;
    margin: 5px 0px;
    margin-bottom: 0;
}
.marketplaceserplist p{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #666666;
}
.marketplace-ege{
    margin-top: -10px;
}
.marketplace .marketPlacetypesContent li a.nav-link{
    border-radius: 24.0796px !important;
    padding: 10px 30px !important;
    margin-bottom: 20px !important;
    color: #5b73e8 !important;
    border-color: #5b73e8 !important;
    height: auto !important;
    margin: 0 10px !important;
    background-color: transparent;
}
.marketplace .marketPlacetypesContent li a.nav-link.active,
.marketplace .marketPlacetypesContent li a.nav-link:hover{
    color: #fff !important;
    background-color: #5b73e8;
    border-color: #5b73e8;
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
}
.marketPlacetypesContent .tab-content{
    position: static;
}
.egeapphdng {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 126.7%;
    color: #000000;
    margin: 20px 0 10px 0;
}
.new-tab-pane-ege {
    position: static;
    width: 100%;
}

.new-tab-pane-ege .col.card img{
    width:80%;
}

#erpnew .Ecommerce{
    display: none;
}
#eCommercenew .Erp{
    display: none;
}
.tab-pane .IntegrationTypenew {
    width: 100%;
    position: absolute;
    left: 0;
    padding: 20px;
    top: 30px;
    z-index: 9;
}
.marketplacesec .IntegrationTypenew{
    left: 0;
    width: 95%;
    height: auto;
}
.marketplacesec .mainChooseVersion .plr-20{
    padding: 0 20px;
}
.viewMore{
    cursor: pointer;
}

.marketplacesec .egeChooseversion .integration-type-listing .connector-logo-container img.leftimage{
    margin: 0;
    width: 90%;
    position: static;
    left: -24px;
    top: -28px;
    margin-top: 4px;
}
.marketplacesec .egeChooseversion .integration-type-listing .connector-logo-container img.rightimage {
    margin: 0;
    width: 90%;
    position: static;
    left: auto;
    top: -28px;
    right: 110px;
    margin-top: 9px;
}
.marketplace .nav-tabs .nav-item.show .nav-link, .marketplace .nav-tabs .nav-link.active {
    font-weight: 900;
    font-size: 16px;
}
.marketplace .waves-effect{
    width: auto !important;
    padding: 0 !important;
}
.tabNewAdded{
    width: 100%;
    padding: 20px 12px;
    top: -65px;
    position: relative;
}