.slick-prev:before,
.slick-next:before {
  color: #000000 !important;
}
.slick-initialized .slick-slide {
  padding: 0 5px;
}
.newMyProducts .connectortab.active {
  border-right: 1px solid #98B5EA !important;
  border-radius: 8px !important;
}
.newMyProducts .selectionProcessnew {
  /* position: relative; */
  position:absolute;
  margin-top: 0px;
  height: 77px;
  left: auto;
  top: 0;
}
.newMyProducts .newMediaSection {
  /* position: relative;
    padding: 10px; */
  position: relative;
  padding: 10px;
  top: 0;
}
.newMyProducts .newMediaSection .media-body.align-self-center.overflow-hidden {
  min-width: 165px;
  max-width: 100%;
}
.newMyProducts .connection-info {
  max-width: 962px;
  margin: 0 auto;
}
.card.custom_mn_card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  border-radius: 0.25rem;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
}
.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}
.newMyProducts .mndesc {
  margin-bottom: 0 !important;
  min-height: 300px;
  height: auto;
}
.newMyProducts .mndesc .card-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000;
  font-weight: 600;
  border-bottom: 0px;
}
.newMyProducts .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f6f6f6;
}
.newMyProducts .card-header.bg-transparent {
  padding: 5px 0 0 0;
  border: none;
}
.newMyProducts .card.custom_mn_card .card-body.pleft-0.pright-0 {
  padding-top: 0px !important;
}
.pleft-0 {
  padding-left: 0px;
}
.pright-0 {
  padding-right: 0px;
}
.p-10 {
  padding: 10px;
}
.newMyProducts .scrollingContainer {
  height: auto;
}
.newMyProducts .newMediaSection .mr-2 {
  margin-right: 0px !important;
}
.newMyProducts .syncContainer {
  margin: 0 auto;
}
.newMyProducts .erpCircle,
.newMyProducts .ecommCircle {
  width: 40px;
  height: 40px;
  background-size: 100%;
  margin-top: 5px;
}
.newMyProducts .endtext {
  font-size: 14px;
  line-height: 40px;
}
.newMyProducts .erp-ec-dir {
  min-width: 105px;
  margin-top: 3px;
}
.newMyProducts .syncnmbr {
  width: 44px;
  height: 14px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  color: #1E54B8;
  display: block;
  margin: 0 auto;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}
.newMyProducts .titlecustomNew {
  float: left;
}
.newMyProducts .tabMenuContainer {
  float: right;
  width: 100%;
  max-width: 530px;
  margin-top: -8px;
}
.newMyProducts .tabMenuContainer ul {
  list-style: none;
}
.newMyProducts .tabMenuContainer ul li {
  float: left;
  padding: 0 10px;
}
.newMyProducts .tabMenuContainer ul li a {
  display: block;
}
.newMyProducts .tabMenuContainer .nav > li > a {
  color: #495057;
  font-weight: 500;
}
.newMyProducts .tabMenuContainer .nav-link.active {
  color: #5b73e8;
  border-bottom: 2px solid #5b73e8;
}
.newMyProducts .connectorshotdesc th {
  overflow: hidden;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.newMyProducts .slick-track {
  min-width: 100% !important;
}
.newMyProducts .ptb-10 {
  padding: 10px 0px;
}
.card-header.p-10 {
  padding: 10px;
}
.newMyProducts .css-14el2xx-placeholder {
  font-size: 13px;
}
.newMyProducts .newPr-0 {
  padding-right: 0px;
}
.newMyProducts .form-check {
  padding-left: 2em;
}
.newMyProducts .form-check-label {
  font-size: 12px;
}
.newMyProducts .addMapping{
  width: 48px;
  height: 40px;
  background: linear-gradient(135deg,#437fed 6.65%,#1e54b8 91.91%);
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 25px;
  text-align: center;
}
.table thead th {
  color: #495057;
  font-size: .9rem;
}
.table tbody td{
  font-size: .9rem;
}
.entityBody.mappingtableContainer{
  position: relative;
  top: -16px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa;
}
.entityHeader table tr th,
.entityBody table tr td{
  text-align: center;
}
.entityHeader table tr th:first-child,
.entityBody table tr td:first-child{
  width: 410.48px;
  text-align: left;
}
.switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 40px;
}
.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}
.switch .slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}
.switch .slider.round {
  border-radius: 10px;
}
.switch input:checked+.slider {
  background: linear-gradient(135deg,#437fed 6.65%,#1e54b8 91.91%);
}
.switch .slider:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 16px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 16px;
  border-radius: 50%;
}
.switch input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  transform: translateX(18px);
}
.actionButton{
  padding: 0 10px 10px 0;
  float: left;
  width: 100%;
}
.actionButton a.btn{
  float: left !important;
}




