.stems li, .options li {
    list-style: none;
    padding: 0.5rem 1.5rem;
    margin: 1rem;
}
.stems li:hover, .options li:hover {
    padding: 0.4rem 1.5rem 0.6rem;
}
.stems, .options {
    width: 320px;
    float: left;
    max-width: 320px;
    height: auto;
    max-height: 290px;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 5px;
    margin-bottom: 15px;
}
.stems{
    margin-right: 20px;
}
.stems ol,.options ol{
    margin-left: 0px;
    padding-left: 0px;
}
.stems li, .options li {
    background: #e5e5e5;
    cursor: pointer;
    margin-left: 0;
    margin-right: 0px;
}
.stems li.matched, .options li.matched, .stems li.matched:hover, .options li.matched:hover {
    background: #aca;
}
.stems li.selected, .options li.selected, .stems li.selected:hover, .options li.selected:hover {
    background: #a9ffa9;
    font-weight: bold;
    box-shadow: 1px 2px 1px #666;
}
.stems li:hover, .options li:hover {
    background: #a9cca9;
}
.line {
    width: 100%;
    height: 2px;
    background-color: #e5e5e5;
    z-index: -10;
}
.line.highlighted {
    background-color: green;
    box-shadow: 1px 2px 1px #666;
    height: 3px;
    z-index: 99;
}
.line.highlighted .point {
    border-left: 16px solid green;
}
.line .point {
    width: 0;
    height: 0;
    margin: -6px -3px 0 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 16px solid #e5e5e5;
    float: right;
}
