/* Popup style */
.popup {  
  position: fixed;  
  width: 100%;  
  height: 100%;  
  top: 0;  
  left: 0;  
  right: 0;  
  bottom: 0;  
  margin: auto;  
  background-color: rgba(0,0,0, 0.5);  
}  
.popup_inner{  
  position: relative;
  left: auto;
  right: auto;
  top: 6%;
  margin: auto;
  border-radius: 20px;
  width: 80%;
  background: white;
  padding: 21px;
}
.addPrerequisites{

}
.addPrerequisites h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.detailsContainer {
  background: #FFFFFF;
  border: 2px solid #C6C6C6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
}
.detailsContainer h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  color: #000000;
}
.detailsContainer table th,
.detailsContainer table td {
  padding: 0px;
  border: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
  color: #999999;
}
.detailsContainer table td{
color: #444444;
}
.addPrerequisites .cancancel{
  width: 147px;
  height: 41px;
  background: #ffffff;
  border-radius: 40px;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  color: #1A35C2;
  border: 1px solid #1A35C2;
}
.addPrerequisites .subsubmit{
  width: 147px;
  height: 41px;
  /* background: #C6C6C6; */
  border-radius: 40px;
  margin-left: 20px;
}
#prerequisites .form-control{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.form-group input::placeholder,
#prerequisites .css-14el2xx-placeholder{
  font-size: 12px;
}
.errorPrerequisites{
  color: red;
}
.featuer-container .card-body.customFormControl {
  padding: 10px !important;
  height: 210px;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.featuer-container .card-body{
  overflow-y: scroll !important;
}