.toggleImg{
    width: 100%;
    max-height: 30px;
    display: block;
    margin: 0 auto;
}
/* .srcImgNew{
    max-height: 51.3px;
} */
.srcImgNew {
    max-height: 51.3px;
    width: 59px;
    max-width: 59px;
}
.summaryTitle {
    width: 100%;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #1e54b8;
    overflow: hidden;
    display: block;
    white-space: nowrap; /* don't break the line */
    text-overflow: ellipsis;
    top: 50%;
    transform: translateY(-50%);
}
.imgTitlenew {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #437fed;
}
.card-body-customnew{
    padding: 0 5px;
}
.customToggleButton{
    cursor: pointer;
    text-align: center;
}
h5.sourcetotargettitle {
    font-size: 20px;
    color: #000;
    margin-bottom: 14px;
}
.last-pull, .last-push, h5.sourcetotargettitle {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.last-pull, .last-push {
    font-size: 14px;
    color: #999;
    margin-bottom: 10px;
    display: block;
    width: 100%;
    float: left;
}
.last-pull b, .last-push b {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #666;
    padding-left: 10px;
}
.pushandpullstatus{
    position: relative;
    height: 100%;
}
.pushContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.brpd{
    border: 2px solid #5b73e8!important;
    padding: 10px 0;
    border-radius: 0.25rem;
}
.mrt-10{
    margin-top: 10px !important;
}
.viewSummaryNew.table td, .viewSummaryNew.table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #c6c6c6;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    border-right: 1px solid #c6c6c6;
}
.viewSummaryNew.table .thead-light th {
    color: #495057;
    border-color: #f2f4fe;
    background: #f2f4fe;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #999;
    border-right: 1px solid #c6c6c6;
}
.selectFlip {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}
.summaryDateFrom, .summaryDateTo {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    margin: 0 10px !important;
    padding: 10px 0;
}
.input-daterange input.form-control {
    background-image: url("../ViewDetails/images/input_calender.svg")!important;
    background-repeat: no-repeat!important;
    background-position: 180px 12px!important;
    cursor: pointer;
}
.input-daterange input {
    background: #fff;
    border: 1px solid #c6c6c6;
    box-sizing: border-box;
    border-radius: 20px!important;
}
.calenderIcon {
    display: none!important;
}
.calenderIcon {
    position: relative;
    left: -25px;
    top: 6px;
    z-index: 9;
}
.calenderIcon img {
    width: 15px;
    height: 15px;
}
.detailsCal .react-datepicker-wrapper{
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}
input.form-control::placeholder{
    font-size: 14px;
}
a.viewDetails{
    cursor: pointer;
}
.errorNew::placeholder{
    color: tomato;
}
.table .alert.alert-info {
    color: #055160 !important;
    background-color: #cff4fc !important;
    border-color: #b6effb !important;
    padding: 1rem;
    margin-bottom: 0;
    font-size: 15px;
}