
.accordion-wrapper {
    padding: 0;
}
.accordion-wrapper input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
.accordion-wrapper .accordion {
    width: 100%;
    color: white;
    overflow: hidden;
    margin-bottom: 0px;
}
.accordion-wrapper .accordion:last-child{
    margin-bottom: 0;
}
.accordion-wrapper .accordion-label {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 16px;
    background: rgba(4,57,94,.8);
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
}
.accordion-wrapper .accordion-label:hover {
    background: none;
}
.accordion-wrapper .accordion-label::after {
    content: "\276F";
    width: 16px;
    height: 16px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.accordion-wrapper .accordion-content {
    max-height: 0;
    padding: 0 16px;
    color: rgba(4,57,94,1);
    background: white;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow-y: scroll;
    height: 273px;
}
.accordion-wrapper .accordion-content p{
    margin: 0;
    color: rgba(4,57,94,.7);
    font-size: 18px;
}
.accordion-wrapper input:checked + .accordion-label {
    background: none;
}
.accordion-wrapper input:checked + .accordion-label::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.accordion-wrapper input:checked ~ .accordion-content {
    max-height: 100vh;
    padding: 16px;
}
  
.featuer-container .card-body{
    padding: 0px !important;
}
#cardbdy-ftrnew{
    margin: 0 !important;
}

.accordion-wrapper .accordion-label{
    background: none;
    background-color: transparent;
    color: #000000;
    text-align: left;
    color: #000;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: .7px;
    font-size: 12px;
    font-family: Poppins;
    font-style: normal;
    background: transparent;
    border-bottom: 1.22222px solid #c6c6c6;
}


.slide-pane__content::-webkit-scrollbar-track, #style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.slide-pane__content::-webkit-scrollbar-track, #style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.slide-pane__content::-webkit-scrollbar-track, #style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

