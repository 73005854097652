.selecterptd{
    width: 30%;
    padding: 0 5px;
    padding-top: 10px;
}
.selectecommercetd{
    width: 30%;
    padding: 0 5px;
    padding-top: 10px;
}
.mappingtableContainer{
    max-height: 190px;
    overflow-y: scroll;
    overflow-x: hidden;
}
