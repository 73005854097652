.info-max{
    min-height: 495px;
    max-height: 530px;
}
.custompaddingdash{
    padding: 90px 10px 0px;
}
/* Modal Popup Start */
.mn-dashboard .modal-open .modal{
    padding-right: 0px;
}
.mn-dashboard .modal-dialog{
    margin: 0 auto;
    width: 100%;
    max-width: 100%;

}
.modal-content.welcomePopup {
    width: 924px;
    height: 500px;
    margin: 0 auto;
    position: relative;
    transform: translateY(20%);
    top: -50%;
}
.modal-content.welcomePopup .modal-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.modal-content.welcomePopup .welcometag {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    display: block;
}
.modal-content.welcomePopup .createConnector{
    width: 180px;
    height: 180px;
    background: #C4C4C4;
    border-radius: 50%;
    margin: 0 auto;
}
.modal-content.welcomePopup h3.title-modal-new {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-top: 10px;
}
.modal-content.welcomePopup p.desc-modal-new{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: #999999;
    margin-bottom: 0;
}
.modal-content.welcomePopup .card-body{
    border-bottom: 1px solid #C6C6C6;
    border-radius: 0;
}
.modal-content.welcomePopup .card-footer{
    background-color: transparent;
}
.modal-content.welcomePopup .card-footer .skipModal{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1A35C2;
    cursor: pointer;
}
.modal-content.welcomePopup .card-footer .getwalkthroughModal{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1A35C2;    
    cursor: pointer;
}
/* Modal Popup Start */

.placeholder-item {
    box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
    border-radius: 4px;
    height: auto;
    position: relative;
    overflow: hidden;
}
.placeholder-item::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}

.in-activenew,
.mn-dashboard .nav-pills .nav-link.active.in-activenew{
    background-image: none !important;
    width: 96% !important;
    max-width: 96% !important;
    height: 78px;
    background: #FFFFFF;
    border: 1px solid #C6C6C6;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 12px !important;
}
.card.crd-ctab.placeholder-item{
    height: 121.6px;
}