.image{
    min-width: 284px;
    position: relative;
    display: block;
    left: 60px;
}
.Timage{
    min-width: 284px;
    position: relative;
    display: block;
    right: 60px;
}
.linkIconLanding{
    position: relative;
    top: 50%;
    left: 0px;
}
.selectedConnectorImgCon{
    width: 100px;
    height: 100px;
    border: 1px solid #000000;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    float: left;
    position: relative;
}
.imgCon{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.mainContainer {
    background: #fff;
    border-radius: 9.73798px;
    box-shadow: 0 4.86899px 24.3449px #0000001a;
}
.imgCon img{
    margin: 0 auto;
}
.floatRight{
    float: right;
}
.connectorName{
    font-size: 17px !important;
    line-height: normal !important;
    min-height: 34px !important;
    margin: 20px 0 0 0;
}
.mnTitle{
    font-family: 'Calibri' !important;
    font-weight: 700 !important;
    font-size: 1rem !important;
    line-height: 1.2 !important;
}
.mnContainer{
    margin: 0 0px;
    margin-top: 10px;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    word-wrap: break-word;
    border-radius: 7px;
    padding: 0.75rem;
    height: 90vh; /* Full viewport height */
    display: flex; /* Optional: if you want to center content inside */
    flex-direction: column; /* Optional: ensure vertical layout */
    overflow-y: auto;
    margin-bottom: 5px;
}
.Grid{
    height: 80vh; /* Full viewport height */
    display: flex; /* Optional: if you want to center content inside */
    flex-direction: column; /* Optional: ensure vertical layout */
}
.Button{
    background: #3182ce !important;
    color: #FFFFFF !important;
}
.warningMessage{
    color: #005bc7;
    font-family: calibri;
    font-size: 15px;
    padding: 6px;
    border-radius: 7px;
    border: 1px solid #e2e8f0;
    margin-top: 10px;
}
.parentBoxofLoader{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    background-color: #191c1ee3;
    z-index: 99;
  }