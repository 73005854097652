.mapping-tab-sec{}
.mapping-tab-sec ul {
    padding: 0;
}
.mapping-tab-sec ul li{
    list-style: none;
}
.mapping-tab-sec ul li a{
    background: #f2f2f2!important;
    height: 43px!important;
    margin-bottom: 0!important;
    border-radius: 0!important;
    border: none;
    border-bottom: 1px solid #c6c6c6;
}
.mapping-tab-sec ul li a.active {
    background: linear-gradient(135deg,#437fed 6.65%,#1e54b8 91.91%)!important;
    color: #fff;
}
.nav-link.mapping-tab{
    color: #495057;
    font-weight: 500;
}
.newMyProducts .css-b62m3t-container {
    padding: 0px 5px !important;
}
.mappingsNew{

}
.mappingsNew tr td.selecterptd, .mappingsNew tr td.selectecommercetd {
    width: 30%;
    /* min-width: 204.5px;
    max-width: 204.5px; */
}
.mappingForm{

}
.resultTable{

}
select.form-control{
    background: #f2f2f2;
    border-radius: 8px;
    border: none;
}
.mappingsNew .css-1s2u09g-control {
    border: none !important;
    background-color: #f2f2f2 !important;
}
.mappingsNew .css-1pahdxg-control{
    border: none !important;
    background-color: #f2f2f2 !important;
}
.deleteMapping {
    background: #fff;
    box-sizing: border-box;
    width: 48px;
    height: 40px;
    border-radius: 8px;
    color: #dc332e;
    font-size: 25px;
    text-align: center;
    border: 1px solid #dc332e;
}
.newMyProducts .form-check {
    width: 109.97px;
}
.mappingsNew.resultTable tr td {
    padding-top: 5px;
}
.mappingsNew .css-qc6sy-singleValue{
    font-size: 13px;
}
.mappingtableContainer {
    max-height: 190px;
    overflow-y: scroll;
    overflow-x: hidden;
}
body .mappingtableContainer::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    position: absolute;
    
}
body .mappingtableContainer::-webkit-scrollbar
{
	width: 6px;
    background-color: #F5F5F5;
    position: absolute;
    visibility: hidden;
}
body .mappingtableContainer::-webkit-scrollbar-thumb
{
    background-color: #000000;
}
.newMyProducts .addMapping {
    position: relative;
    /* right: 6px; */
}
.newMyProducts .deleteMapping{
    position: relative;
    /* right: 6px; */
}
.mappingFormContainer{

}
.custom-control-label:after, .custom-control-label:before{
    border-radius: 0.25rem;
}
.css-qbdosj-Input{
    display: none !important;
}
.css-1jqq78o-placeholder,
.css-1dimb5e-singleValue{
    font-size: 12px;
}

.newMyProducts .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 8px 10px;
    font-size: 12px;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    font-size: 12px !important;
}
.newMyProducts .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper,
.MuiPaper-root{
    background-color: #ffffff !important;
}
.newMyProducts .css-10wo9uf-option,
.newMyProducts .css-d7l1ni-option,
.newMyProducts select.form-control{
    font-size: 12px !important;
    outline: none !important;
    box-shadow: none;
    background-color: #f2f2f2;
    appearance: auto;
    border: 1px solid transparent;
}
.mappingsNew tr td.selecterptd, .mappingsNew tr td.selectecommercetd {
    padding: 0 5px;
}
.submitButton {
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
    border-radius: 40px;
    color: #FFFFFF;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    border: none;
    padding: 5px 10px;
    position: absolute;
    right: 21px;
    top: 4px;
}
.version-field.erpFieldnew{
    height: 39.03px;
    max-width: 190.95px;
}
.deleteMappingNew {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
}
.spinner-container{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #1e54b840;
    z-index: 99;
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  position: relative;
  top: 50%;
  left: 50%;
}
.errorValidation{
  border: 1px solid #dc332e !important;
  color: #dc332e;
}
.mAuto{
    margin: auto;
}
table.tableAlign .deleteMapping{
    right: -20px;
}
.multiLocationForm .addMapping{
    right: -8px;
}
.productAttributeMapping .slide-pane_from_right.content-after-open {
    width: 80% !important;
}
.productAttributeMapping .slide-pane__header{
    padding-left: 0 !important;
}
.productAttributeMapping .slide-pane__content{
    padding-left: 50px !important;
    position: static;
}
.attributeToggle .switch .slider{
    background: linear-gradient(135deg, #437fed 6.65%, #1e54b8 91.91%);
}

.d-none{
    display: none;
}

.spinner-container12{
    position: fixed;
    z-index: 9999;
}