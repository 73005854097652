.ege-mn-title{
    width: 364px;
    height: 106px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 53px;
    text-transform: uppercase;
    color: #000000;
}
.ege-mn-desc{
    width: 468px;
    height: 147px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #666666;
}
.create-yours-now{
    width: 271.33px;
    height: 43.89px;
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
    border-radius: 48.8889px;
    color: #FFFFFF;
    border: none;
}
.learnmorenew{
    cursor: pointer;
    display: block;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 126.7%;
    color: #1E54B8 !important;
    margin-top: 10px;
}
.ege-learnmore{
    
}

.egeChooseversion {
    z-index: 999;
}
.featuer-container .card-body {
    overflow-y: hidden;
    overflow-x:hidden
}

.featuer-container h6.subNewTitleway {
    text-align: left;
    color: #000000;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0.7px;
    font-size: 12px;
}
.featuer-container .card-body.overflownewhight{
    overflow-y: scroll;
    height: 220px;
    padding-bottom: 0px;
}
.custom-accordion .overflownewhight .card + .card{
    margin-top: 0px;
}
.mdi-chevron-up:before {
    font-size: 24px;
}