input:disabled + .slider{
    cursor: not-allowed
}
.loaderContainer, .loaderContainer1, .loaderContainer2 {
    position: absolute;
    z-index: 99999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.loader {
    border: 9px solid #f3f3f3;
    border-radius: 50%;
    border-top: 9px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: 0 auto;
    position: relative;
    top: 50%;
}
.errorEntityMessage{
    padding: 5px 0 5px 0;
    color: #2e67cf;
}
.errorEntityMessage.error{
    color: #de4d34;
}
