.egeChooseversion{
    position: absolute;
    top: 0px;
    background-color: #ffffff;
    width: 100%;   
}
.featuer-container{
    height: 511px;
    background: #FFFFFF;
    border: 1.22222px solid #C6C6C6;
    box-sizing: border-box;
    border-radius: 9.77778px;
}
.featuer-header{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 17.1111px;
    line-height: 26px;
    color: #000000;
}
.featuer-container .card-header{
    background: transparent;
    border-bottom: 1.22222px solid #C6C6C6;
}
.version-field,
.version-field:focus {
    background: #F2F4FE;
    border-radius: 4.88889px;
    border: none;
    height: 48.89px;
    font-style: normal;
    font-weight: normal;
    font-size: 14.6667px;
    line-height: 17px;
    text-transform: uppercase;
    color: #C6C6C6;
    border-left: 7px solid #CDD5FF;
}
.version-field:focus{
    border-left: 7px solid #1E54B8;
}
.featuer-btn-confirm {
    display: block;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 10px 10px 10px;
    width: 283.33px;
    height: 49.89px;
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
    border-radius: 40px;
    text-align: center;
}
.card.featuer-container .card-footer{
    background: transparent;
}
.featuer-circle {
    width: 56.22px;
    height: 56.22px;
    background: #F2F2F2;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
}
.fwdsyncimg {
    position: relative;
    top: 15px;
}
.rvrsyncimg{
    position: relative;
    top: 15px;
}
.fwdsyncimg-pos {
    position: relative;
    top: 11px;
}
.rvrsyncimg-pos {
    position: relative;
    top: -1px;
}
.featuer-container h6{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 9.77778px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #999999;
    padding: 5px 0;
}
.ege-bc-connector-logo-container{
    width: 80%;
    margin: 0 auto;
}
.ege-bc-connector-logo-container img{
    width: 100%;
}
.newoverflowy{
    overflow-y: overlay;
}
.egeChooseversion .connector-logo-container img {
    position: static !important;
    width: 90% !important;
    margin-top: 5px !important;
}

body .newoverflowy::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    position: absolute;
}

body .newoverflowy::-webkit-scrollbar
{
	width: 6px;
    background-color: #F5F5F5;
    position: absolute;
}

body .newoverflowy::-webkit-scrollbar-thumb
{
    background-color: #000000;
}
.egeChooseversion .awssetup {
    top: 0;
    left: -30px;
    padding-left: 30px;
    padding-right: 0;
    width: 105%;
    z-index: 999999999999;
    height: 100%;
}

#loaderContainerfeatuers{
    background-color: #00000085;
}
#loaderContainerfeatuers p{
    margin: 10px auto;
    position: relative;
    top: 50%;
    width: 50%;
    text-align: center;
    line-height: 30px;
    color: #ffffff;
}
.connector-logo-container {
    text-align: center;
    border-radius: 50%;
    width: 73px;
    height: 73px;
    margin: 0 auto;
    border: 1.21725px solid rgba(26,53,194,.3);
    overflow: hidden;
}
.connector-logo-container img.leftimage {
    margin: 0;
    width: 290%;
    position: relative;
    left: -24px;
    top: -28px;
}
.connector-logo-container img.rightimage {
    margin: 0;
    width: 290%;
    position: relative;
    left: auto;
    top: -28px;
    right: 110px;
}
.css-6j8wv5-Input{
    margin: 0px !important;
}
.css-6j8wv5-Input:after{
    display: none !important;
}
.css-b62m3t-container {
    padding: 6px 0 !important;
}
.css-1s2u09g-control{
    border: none !important;
    background-color: #f2f4fe !important;
}
.css-6j8wv5-Input:focus-visible{
    border: none !important;
}
.css-1pahdxg-control{
    border: none !important;
}
.form-new-control{
    background-color: #f2f4fe;
    border-radius: 4.88889px;
    height: 48.89px;
    font-style: normal;
    font-weight: 400;
    font-size: 14.6667px;
    line-height: 17px;
    text-transform: uppercase;
    color: #c6c6c6;
    border: none;
    border-left: 7px solid #cdd5ff;
}
.form-select:focus{
    border: none !important;
    box-shadow: none !important;
    border-left: 7px solid #1E54B8 !important
}
.featuer-circle .fwdsyncimg, .fwdsyncimg {
    top: 0;
}
.featuer-circle .fwdsyncimg img {
    width: 100%!important;
}
.featuer-container .card-body{
    overflow-y: scroll;
}
.featuer-container button.customPanopen:first-child, 
.featuer-container button.customPopupnew:first-child{
    margin: 0 auto;
}
.questionsPopup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000a3;
}

.accordianHeader{
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1.22222px solid #c6c6c6;
    padding-bottom: 10px;
}