.color-blue{
    color: #1A35C2;
}
.slide-pane__overlay.ReactModal__Overlay--after-open {
    background-color: rgb(1 1 1 / 66%);
    transition: background-color 0.5s;
}
.slide-pane__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0);
    z-index: 9999;
}
.connector-popup{
    max-width: 891px;
    padding-left: 96px;
    padding-top: 36px;
    padding-right: 96px;
}
.connector-popup .slide-pane__header{
    display: none;
}
.connector-popup .popup-close {
    border: none;
    background: transparent;
    text-align: right;
    position: absolute;
    z-index: 9999;
    right: 10px;
    top: 0px;
}
h3.newconnector-title{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
}
h4.newconnector-sub-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    border-bottom: 1px solid #C6C6C6;
    padding-bottom: 10px;
}
.connector-listing{
    width: 137.22px;
    height: 137px;
    background: #FFFFFF;
    border-radius: 24.0796px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    padding-top: 14px;
    overflow: hidden;
}
.connector-listing .connector-logo-container {
    width: 86px;
    height: 86px;
    background: #FFFFFF;
    border: 1.22222px solid rgba(26, 53, 194, 0.3);
    box-sizing: border-box;
    border-radius: 50%;
    margin: auto;
    text-align: center;
    overflow: hidden;
}
.IntegrationTypenew .connector-logo-container {
    text-align: center;
    border-radius: 50%;
    width: 73px;
    height: 73px;
    margin: 0 auto;
    border: 1.21725px solid rgba(26, 53, 194, 0.3);
    overflow: hidden;
}
/* .connector-logo-container img {
    width: 50px;
    margin: 17px 0px;
} */
.connector-logo-container img {
    width: 90%;
    margin: 5px 0px;
}
.IntegrationTypenew .connector-logo-container img.leftimage {
    margin: 0;
    width: 290%;
    position: relative;
    left: -24px;
    top: -28px;
}
.IntegrationTypenew .connector-logo-container img.rightimage {
    margin: 0;
    width: 290%;
    position: relative;
    left: auto;
    top: -28px;
    right: 110px;
}
.mainChooseVersion .connector-logo-container img.leftimage {
    margin: 0;
    width: 290%;
    position: relative;
    left: -24px;
    top: -28px;
}
.mainChooseVersion .connector-logo-container img.rightimage {
    margin: 0;
    width: 290%;
    position: relative;
    left: auto;
    top: -28px;
    right: 110px;
}
.add-connector-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
    text-align: center;
}
.conhoverselect {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100px;
    color: #1A35C2;
    display: none;
}
.conhover:hover .conhoverselect{
    cursor: pointer;
    display: block;
}
.prev-back{
    cursor: pointer;
    padding-right: 10px;
}
.integration-type-listing{
    background: #FFFFFF;
    box-shadow: 0px 4.86899px 24.3449px rgba(0, 0, 0, 0.1);
    border-radius: 9.73798px;
    overflow: hidden;
    column-count: 2;
}
.attatchicon:after{
    content: '\e99d';
    position: absolute;
    width: 40px;
    height: 100%;
    top: 0;
    left: -27px;
    font-size: 40px;
    font-family: unicons-line;
    font-style: normal;
    font-weight: 400;
    display: inline-block;
    text-decoration: inherit;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 130px;
    -webkit-font-smoothing: antialiased;
    color: #999999;;
}
.integration-type-listing .add-connector-title {
    padding: 10px 0;
    padding-bottom: 0;
    margin-bottom: 0;
}
.integration-type-listing:hover .conhoverselect{
    cursor: pointer;
    display: block;
}
.integration-type-listing .conhoverselect{
    z-index: 9;
    position: absolute;
    width: 305px;
    background: #000000;
    border-radius: 10px;
    background: rgb(255 255 255 / 61%);
}
.integration-type-listing .new-connector{
    margin: 65px auto;
    padding: 7px 30px;
}

.selectNewconnector{

}
.prev-back .uil-angle-left{
    float: left;
    margin-right: -5px;
    margin-top: 1px;
}
.prev-back .uil-angle-left:before{
    font-size: 24px;
}

.slide-pane_from_right.content-after-open{
    width: 70% !important;
}
.slide-pane__header{
    padding-left: 90px !important;
}
.slide-pane__content{
    padding-left: 130px !important;
    overflow-x: hidden;
}
.slide-pane__title-wrapper{
    margin-left: 0px !important;
}
.IntegrationTypenew{
    width: auto !important;
    position: relative;
}
.integration-type-listing .conhoverselect {
    width: 94%;
}
.IntegrationTypenew, .mainChooseVersion{
    margin-top: 0px !important;
}
.IntegrationTypenew .connector-logo-container {
    text-align: center;
    border-radius: 50%;
    width: 73px;
    height: 73px;
    margin: 0 auto;
    border: 1.21725px solid rgba(26,53,194,.3);
    overflow: hidden;
}