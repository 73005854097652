.ege-learnmore .nav-link{
    height: 60px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 126.7%;
    color: #98B5EA;    
    border: 0px;
    width: 175px;
}
.ege-learnmore .nav-link.active {
    color: #437FED;
    background-color: #007bff;
    background: #F2F4FE;
    border: 1px solid #98B5EA;
    border: 0;
    background-color: transparent;
    border-left: 2px solid #000000;
    border-radius: 0;
    height: 60px;
}
.ege-learnmore {
    padding-left: 0;
    padding-right: 50px;
    padding-top: 10px;
    width: 100% !important;
    max-width: 60%;
}
.no-boxshadow {
    box-shadow: none;
}
.no-border{
    border: none;
}
.viewdetails .card-header {
    background-color: transparent;
    border-bottom: 1px solid #c6c6c6;
}
.viewdetails h3.heading {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #000;
    background-color: transparent;
}
.ege-learnmore .slide-pane__content{
    padding-left: 40px !important;
}
.vpillsTabContentNew p{
    font-size: .9rem;
}