.selecterptd{
    width: 30%;
    padding: 0 5px;
    padding-top: 10px;
}
.selectecommercetd{
    width: 30%;
    padding: 0 5px;
    padding-top: 10px;
}
.mappingtableContainer{
    max-height: 190px;
    overflow: hidden;
    overflow-y: auto;
    /* min-height: 190px; */
}

body .mappingtableContainer::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    position: absolute;
    
}
body .mappingtableContainer::-webkit-scrollbar
{
	width: 6px;
    background-color: #F5F5F5;
    position: absolute;
    visibility: hidden;
}

body .mappingtableContainer::-webkit-scrollbar-thumb
{
    background-color: #000000;
}
