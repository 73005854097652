.input-daterange input{
    background: #FFFFFF;
    border: 1px solid #C6C6C6;
    box-sizing: border-box;
    border-radius: 20px !important;
}
.positionrelative{
    position: relative;
}
.topnine{
    top: 9px;
}
.summaryDateFrom, .summaryDateTo {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #303030;
    margin: 0 10px;
    padding: 10px 0;
}
.summaryFilter{
    cursor: pointer;
}
.popover{
    background: #FFFFFF;
    border-radius: 8px;
    width: 403px;
    height: 485px;
    max-width: 403px;
}
.cursorPointer{
    cursor: pointer;
}
.filter-title{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
}
.popover-header {
    padding: 15px 15px;
    background-color: #ffffff;
}
.popoverClose {
    cursor: pointer;
    color: #666666;
    font-weight: 900;
    font-size: 22px;
}

.popoverForm .popover-body{
    padding: 0px !important;
}
.popoverForm .popover-body .table .thead-light th {
    color: #868686;
    background-color: #F2F4FE;
    border:none;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}
.footerButton {
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
    border-radius: 0px 0px 8px 8px;
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
}
.footerButton td:first-child {
    border-right: 1px solid #FFFFFF;
    width: 50%;
}
.footerButton input, .footerButton input:focus {
    width: 100%;
    background: transparent !important;
    border: none;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff !important;
    text-transform: uppercase;
    outline: none;
}
.infoMessagenew{
    display: none;
}
.infoMessagenew:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #c6c3c3;
    position: absolute;
    left: -4px;
    transform: rotate(45deg);
    top: 13px;
}
.infoIconnew {
    cursor: pointer;
    position: relative;
    top: 2px;
    left: 4px;
}
/* .infoIconnew:hover > .infoMessagenew {
    display: block;
    position: absolute;
    top: -9px;
    width: 310px;
    background-color: #c6c3c3;
    padding: 10px;
    left: 20px;
    color: #000000;
    z-index: 99;
} */
.infoIconnew:hover > .infoMessagenew {
    display: block;
    position: absolute;
    width: 310px;
    background-color: #c6c3c3;
    padding: 10px;
    color: #000000;
    z-index: 99;
    margin-left: 51px;
    margin-top: -25px;
}
.infoIconnew:hover > .Complete.infoMessagenew{
    display: none ;
}
.Complete.uil-info-circle{
    display: none;
}
.viewSummaryNew .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    margin: 20px 20px;
}
.Response.Received.uil-info-circle,
.Request.Received.uil-info-circle{
    display: none ;   
}
.popover-body .table-responsive form table td{
    border-top: 0px solid #f5f6f8;
}
.popover-body .table-responsive form table th{
    vertical-align: middle;
    border-top: 0px solid #f5f6f8;
    border-right: 1px solid #C6C6C6;
}
.popover {
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
    height: 445px;
}
ul.pagination {
    float: right;
    margin: 10px 10px;
}
ul.pagination li{
    padding: 8px 16px;
    border: 1px solid #ddd;
}
ul.pagination li a{

}
ul.pagination li.active {
    background: linear-gradient(135deg , #437FED 6.65%, #1E54B8 91.91%);
}
ul.pagination li.active a{
    color: #ffffff;
}
.viewSummaryNew{
    border-bottom: 1px solid #C6C6C6;
}
.width300px{
    width:300px
}
.infoMessagenewrefrence {
    margin-left: 0 !important;
    right: 30px !important;
    margin-top: 10px !important;
}
.infoMessagenewrefrenceicon{

}
.infoMessagenewrefrence:before {
    left: auto;
    top: -5px;
    right: 64px;
}
.newDisabledMethod:before {
    border: 0px solid #616060;
    background-color: #cccccc !important;
    opacity: 0.3;
}
.newDisabledMethod:after{
    cursor: not-allowed !important;
}



