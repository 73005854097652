.bodyMessage{
    font-size: 20px;
    line-height: 30px;
    color: #444444;
    margin-top: 20px;
    max-width: 870px;
    margin: 0 auto;
}
.parentBoxofLoader{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    background-color: #191c1ee3;
    z-index: 99;
  }