.bg_img-new {
    position: absolute;
    width: 100%;
    top: -390px;
}
.bg_img-new svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }
  .bg_img-new .path {
    animation: draw 20s infinite;
    animation-timing-function: linear;
    stroke: blue;
  }
  .bg_img-new .path-01 {
    animation-delay: 0s;
  }
  .bg_img-new .path-02 {
    animation-delay: 1s;
  }
  .bg_img-new .path-03 {
    animation-delay: 2s;
  }
  .bg_img-new .path-04 {
    animation-delay: 3s;
  }
  .bg_img-new .path-05 {
    animation-delay: 4s;
  }
  
  .bg_img-new #paths-bg g path{
    stroke: #98B5EA;
  }

  .bg_img-new polyline{
    stroke: #98B5EA;
  }
  .bg_img-new rect,
  .bg_img-new circle{
    stroke: #98B5EA;
  }
  .bg_img-new circle{
    fill: #98B5EA;
  }
  
  @keyframes draw {
    0% {
    }
    100% {
      stroke-dashoffset: 0;
      stroke-opacity: 1;
    }
  }
#SVGmain {
  top: 140px;
  left: -540px;
  background-color: transparent;
}
#newSVG {
  top: 1142px;
  left: 1200px;
  background-color: transparent;
}
#newSVG1 {
  top: 340px;
  left: -260px;
  background-color: transparent;
}
#newSVG2 {
  top: 430px;
  left: 1632px;
  background-color: transparent;
}
#newSVG3 {
  top: -50px;
  left: 1950px;
}
#newSVG4 {
  top: 505px;
  left: 1752px;
  background: transparent;
}
#newSVG5 {
  top: 273px;
  left: 2002px;
  background: transparent;
}
#newSVG6 {
  top: -60px;
  left: 2225px;
}
#newSVG7 {
  top: 270px;
  left: 2018px;
  background: transparent;
}
#newSVG8 {
  top: -50px;
  left: 2258px;
}
#newSVG9 {
  top: -110px;
  left: 1481px;
  background: transparent;
}
#newSVG10 {
  top: 768px;
  left: 2174px;
  background: transparent;
}
#newSVG11 {
  top: -60px;
  left: 2570px;
}
#newSVG12 {
  top: 749px;
  left: 2572px;
  background: transparent;
}
#newSVG13 {
  top: 208px;
  left: 2428px;
  background: transparent;
}
#newSVG14 {
  top: 580px;
  left: 2623px;
}
#newSVG15 {
  top: 580px;
  left: 2623px;
}
#newSVG16 {
  top: -50px;
  left: 2783px;
}
#newSVG17 {
  top: 1190px;
  left: 2603px;
  background: transparent;
}
#newSVG18 {
  top: -60px;
  left: 2833px;
}
#newSVG19 {
  top: 1020px;
  left: 2723px;
  background: transparent;
}
#newSVG20 {
  top: 1020px;
  left: 2853px;
  background: transparent;
}