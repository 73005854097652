.featuresContainer{
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    text-align: center;
}
.featuresContainer h3{
    font-size: 12px;
    text-align: center;
}
.featuresImgBox{
    width: 70px;
    margin: 10px auto;
}

.featuresImgBox img{
    width: 100%;
}
.parentBoxofLoader{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    background-color: #191c1ee3;
    z-index: 99;
  }