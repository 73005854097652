*{
    margin: 0px;
    padding: 0px;
}
.cancelMapping{
    border: none;
    background-color: transparent;
    color: red;
    float: right;
    width: 30px;
    text-align: center;
    position: relative;
    padding: 0.5rem 0;
}
.addSelectedAttributeMapping {
    position: static;
}
.options li, .options li:hover {
    padding: 0px;   
}
.titleContainer{
    float: left;
    width: calc(100% - 30px);
    padding: 0.5rem 1.5rem;
    padding-right: 0px;
}
.newMappingTable td{
    vertical-align: middle;
}

.submitButton.submitButton1 {
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
    border-radius: 40px;
    color: #FFFFFF;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    border: none;
    padding: 5px 10px;
    float: right;
    position: static;
    margin-bottom: 15px;
}

.dTable .deleteMappingNew{
    position:static;
}
.d-noneNew{
    display: none !important;
}
.validationMessage{
    color: red;
}
.toolTipCon{
    width: 240px;
    max-width: 240px;
}