.linkIconLanding{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.connectorName{
  font-size: 14px !important;
  line-height: normal !important;
  margin-top: 20px;
  min-height: 34px;
}
.connectorWrapper {
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease;
}
.connectorWrapper:hover .hoverButton {
  opacity: 1;
  transform: translateY(0);
}
.connectorWrapper:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}
.hoverButton {
  position: absolute;
  bottom: 10px;
  left: 10px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.2s ease, transform 0.2s ease;
}
.mnBox{
  border: 1px solid #e7e6e6;
  padding: 10px 10px;
  border-radius: 6px;
}
.wrapContainer{

}
.positionBox{
  float: left;
  width: 45%;
}
.parentBoxofLoader{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0;
  background-color: #191c1ee3;
  z-index: 99;
}