.vertical-menu {
  top: 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 93%;
}
#page-topbar {
  background-image: url(../images/header_bg.svg);
  box-shadow: none;
  border-bottom: 1px solid #C6C6C6;
  background-size: cover;
  left: 0;
  background-color: #ffffff;
  height: 72px;
}
.page-topbarnew {
  /* background-image: url(/static/media/header_bg.39dcf815.svg); */
  box-shadow: none;
  border-bottom: 1px solid #C6C6C6;
  background-size: cover;
  left: 0;
  background-color: #ffffff;
  height: 72px;
  overflow: hidden;
  position: relative;
}
#page-topbar:after {
  content: "";
  width: 100%;
  height: 71px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  opacity: 0.6;
}
.navbar-header {
  position: relative;
  z-index: 9999;
  top: -70px;
}
.offer-custom-notification{
  position: absolute;
  width: 514px;
  height: 213px;
  right: 30px;
  bottom: 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 999999;
}
.offer-custom-notification .notification-panel {
  width: 100%;
  height: 190px;
  background: #F2F4FE;
  border-radius: 8px;
  padding: 10px;
}
.p-12{
  padding: 12px;
}
.offer-title{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  border-bottom: 1px solid #C6C6C6;
  padding-bottom: 6px;
}
 .title-mn {
}
.popup-close-mn{
  color:#437FED;
  cursor: pointer;
}

button .uil-user-circle{
  font-size: 26px;
}
#page-header-notifications-dropdown, #page-header-user-dropdown {
  width: 39px;
  height: 39px;
  background-color: #F2F4FE;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 20%) !important;
  border-radius: 50%;
  margin: 0 0 0 10px;
  border: 3px solid #ffffff;
  padding: 0;
}
.noti-icon i,
button .uil-user-circle,
button .uil-user {
  color: #437FED;
}
button .uil-user{
  font-weight: 900;
}
iframe {
  pointer-events: none;
}

.offer-custom-notification {
  position: fixed;
  bottom: 0px;
}