.text{
    font-family: "Nunito", Sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 1.3px;
}
.heading{
    font-family: "Nunito", Sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 1.3px;
    font-weight: bolder;
}