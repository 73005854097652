.logo-dark span img {
    position: relative;
    top: 10px;
}
#sidebar-menu ul li a i {
    min-width: 24px;
    color: transparent;
    background-color: #f2f2f2;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    margin-right: 10px;
}
#sidebar-menu ul li a:hover i{
    min-width: 24px;
    color: transparent;
    background-color: #f2f2f2;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    margin-right: 10px;
}
#sidebar-menu ul li a.active i {
    border: 2px solid #2a62c9;
    text-align: center;
    line-height: normal;
    overflow: hidden;
    padding: 2px 2px;
    display: none;
}
#sidebar-menu ul li a.active{
    color: #2a62c9;
}
.tab-active .text-muted{
    color: #2a62c9 !important;  
}
.heading-number {
    color: #2a62c9;
    font-size: 50px;
    font-weight: bolder;
    margin: 0 !important;
}
#sidebar-menu ul li a.active i:before {
    background-color: #2a62c9;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    margin-top: 0px;
    line-height: normal;
    display: block;
    position: relative;
    top: 0px;
    left: 0px;
    border: 2px solid #2a62c9;
}
.waves-effect .mn-circle{
    display: none;
}
.waves-effect.active .mn-circle{
    display: inline-block;
    margin-right: 4px;
}
.mn-circle {
    width: 30px;
    height: 30px;
    display: inline-block;
}
.mn-circle img{
    width: 100%;
}
.mn-circleinner {
    width: 16px;
    height: 16px;
    background-color: #2a62c9;
    border-radius: 100%;
    margin: 2px 0 0 2px;
}

.metismenu li {
    max-height: 43.96px;
}
#sidebar-menu ul li a.active span{
    position: relative;
    top: -10px;
}